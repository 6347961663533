/***
*
*   STRUCTURE
*
* HTML Elements
* LAYOUT
*  - @Media
*  - Elements
* BOOTSTRAP
*  - New Elements
*  - Existent Elements
* ACCESSORIES
*  - Buttons
*  - Colors
*  - Cursors
*  - Forms
*  - Header
*  - Icons
* GENERAL
*  - Mobile Exclusive
*  - Others
*
***/

// ######### BODY #########
html { touch-action: manipulation; }
body{
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.2;
}
.logged_in{ background-color: #eef2e3; font-size: 14px; }
.not_logged_in{ background-color: #7ec41a; }
label{ color: #777; }
.main_content{
  input, select, .act_as_input, .input-group-text { font-size: 14px; height: 32px !important; line-height: 27px !important; padding: 3px 5px !important;}
  .table td, .table th{ line-height: 27px; padding: 8px 10px !important; }
}

// ######### LAYOUT #########
@media screen and (max-width: 768px) {
  .search_criteria { padding: 0px; }
  audio{ width: 50px; }
  .listFrame{ padding: 0px !important; }
  .objectFrame{
    padding: 30px 7px !important;
    .card-body{ padding: 10px; }
  }
  .main_app { padding: 40px 0px 0px 0px !important; }
}

@media screen and (min-width: 768px) {
  audio{ height: 20px;width: 125px; }
  .dont-collapse-md {
    display: block !important;
    height: auto !important;
    visibility: visible;
  }
  .dyn-card{ border: none; }
  .topNavSpecial{ margin-top: 0px !important; }
  .main_content{
    // input, select, textarea { background-color: #ecf5df !important; }
    // input.disabed, select.disabled, textarea.disabled { background-color: #ecf5df !important; }
  }
  .card-body{ padding: 10px; }
}

//  - Elements
#error_explanation{ border-radius: 5px; box-shadow: 1px 1px 1px #000; margin-bottom: 5px; padding: 5px 10px; h2{ color: #900; }}
.main_app { background-color: #eef2e3; padding: 55px 10px 10px 10px; width: 100%; }
.main_content { margin: 0px; padding: 10px 0 0 0; }
.listFrame{ border: 1px solid #000; margin-bottom: 5px; min-height: 400px; padding: 10px; }
.objectFrame{ border: 1px solid #000; margin-bottom: 5px; padding: 10px; }

// ######### BOOTSTRAP #########
// - New Elements
.w-25{ width: 25%; }
.w-30{ width: 30%; }
.w-100{ width: 100%; }
.w30{ width: 30px; }
.w70{ width: 70px; }
.w120{ width: 120px; }
.max30{ max-width: 30px; }
.max120{ max-width: 120px; }
.min120{ min-width: 120px; }
.bg-warm{ background-color: #e70; }
.badge-warm{ @extend .bg-warm; }
.text-warm{ color: #e70; }
.text-lgrey{ color: #ccc; }
.newdit{ @extend .float-right; margin-top: 7px; }
.minus30{ width: -webkit-calc(100% - 30px); width: -moz-calc(100% - 30px); width: calc(100% - 30px); }
.minus50{ width: -webkit-calc(100% - 50px); width: -moz-calc(100% - 50px); width: calc(100% - 50px); }
.minus100{ width: -webkit-calc(100% - 100px); width: -moz-calc(100% - 100px); width: calc(100% - 100px); }
.mandatory{ display: none; color: #900; font-size: 11px; font-style: italic; margin-left: 7px; }
.mandatory::after{ content: 'mandatory'; }

// - Existent Elements
.card { margin-bottom: 5px; }
.card-header { padding: 0.25rem 1.25rem; }
.card-title i { color: #aaa; margin-right: 5px; }
.d-block .card{ margin-bottom: 5px; }
.far, .fas { @extend .mr-1; text-align: center; width: 16px; }
.nav-item:hover{ background-color: #f0f0f0; }

// ######### ACESSORIES #########
// -- Buttons
.btnADD{ @extend .badge; @extend .badge-pill; @extend .badge-success; @extend .handCursor; display: none; font-size: 12px; margin: 10px; }
.btn-mobile{ @extend .text-center; @extend .text-light; display: inline-block; height: 53px; line-height: 57px; padding: 0px; }
.btn-mobile:last-child{ @extend .float-right; }
.btn-mobile:hover{ @extend .text-dark; text-decoration: none; }
.btn-mobile.active,.btn-mobile.active:hover{ @extend .bg-chrome; @extend .text-dark; }
// -- Colors
.bg-zimple{ background-color: #7fc31b; }
.bg-zimple-grad{ background: rgb(110,168,25); background: linear-gradient(180deg, rgba(110,168,25,1) 0%, rgba(127,195,27,1) 31%, rgba(146,199,67,1) 100%); }
.bg-zimple-grad-local{ background: rgb(110,168,25); background: linear-gradient(180deg, rgba(110,168,25,1) 0%, rgba(127,195,27,1) 31%, rgba(146,199,67,1) 100%); }
.bg-chrome{ background: rgb(194,194,194); background: linear-gradient(180deg, rgba(194,194,194,1) 0%, rgba(255,255,255,1) 31%, rgba(208,208,208,1) 100%); }
.bg-lgreen{ background: rgb(194,194,194); background: linear-gradient(180deg, #e70 0%, white 31%, #e70 100%); }
.blackHover:hover{ color: #000 !important; }
.blueHover:hover{ color: #00f !important; }
.greenHover:hover{ color: #090 !important; }
.purpleHover:hover{ color: #709 !important; }
.redHover:hover{ color: #900 !important; }

.bg-zimple-grad-local{ .btn-mobile.active,.btn-mobile.active:hover{ @extend .bg-lgreen; } }
.btnInactive{
  color: #ccc !important;
  i{ color: #ccc !important; }
}
// -- Cursors
.handCursor{ cursor: pointer; }
.pointerCursor{ cursor: default; }

// -- Forms
.form-signin {
  border: 3px solid #7fc31b;
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
  margin-top: 50px;
  .checkbox { font-weight: 400; }
  .form-control { position: relative; box-sizing: border-box; height: auto; padding: 10px; font-size: 16px; }
  .form-control:focus { z-index: 2; }
  input[type="email"] { margin-bottom: -1px; border-bottom-right-radius: 0; border-bottom-left-radius: 0; }
  input[type="password"] { margin-bottom: 10px; border-top-left-radius: 0; border-top-right-radius: 0; }
}
.mandatory{ color: #b00; display: none; }
.mandatory::after{ content: "* Mandatory" }
.wrong{ color: #900; display: none; }
.wrong::after{ content: "* Wrong" }

// -- Header
header{
  background-color: transparent;
  min-height: 10px;
  .navbar{
    border-bottom: 1px solid #000;
    box-shadow: 0px 0px 7px #000;
    min-height: 55px;
  }
}
.compressedMenu{
  position: relative;
  i.icon{
    left: -35px;
    position: absolute;
    text-align: center;
    top: 11px;
    width: 20px;
  }
}
.greenHeader,
.greenHeaderLocal{
  background: linear-gradient(90deg, #FFF 60px, #000 2px, #7fc31b 61px, #7fc31b);
  div{
    ul{
      li.active{ background-color: #fff; }
      li:hover{ background-color: #94d830; }
    }
  }
}
.greenHeaderLocal{
  background: rgb(255,255,255);
  background: linear-gradient(135deg, #fff 45px, #000 46px, #e70 47px, #7fc31b);
  .btn-mobile.active,.btn-mobile.active:hover{ @extend .bg-lgreen; }
  div{
    ul{
      li.active{ background-color: #fa0; }
      li:hover{ background-color: #fc0; }
    }
  }
}

// -- Icons
.arrow_show, .arrow_hide, .check_show, .check_hide, .plus_show, .plus_hide{ @extend .mr-1; @extend .far; @extend .d-inline-block; @extend .d-md-none; }
.arrow_show{ @extend .fa-caret-square-down; }
.arrow_hide{ @extend .fa-caret-square-right; @extend .text-muted; }
.check_show{ @extend .fa-check-square; }
.check_hide{ @extend .fa-square; @extend .text-muted; }
.plus_show{ @extend .fa-plus-square; }
.plus_hide{ @extend .fa-minus-square; @extend .text-muted; }


// ######### GENERAL #########
//  - Mobile Exclusive
.mobile_title{
  @extend .d-block;
  @extend .d-md-none;
  @extend .bg-dark;
  @extend .text-light;
  @extend .text-center;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 1;
}
.mobileWarning{
  @extend .d-block;
  @extend .d-md-none;
  @extend .navbar;
  @extend .fixed-top;
  padding: 10px;
  top: 55px;
  .temp_alert{ box-shadow: 10px 5px 2px rgb(255,255,255), -10px -5px 2px rgb(255,255,255), -10px 5px 2px rgb(255,255,255), 10px -5px 2px rgb(255,255,255); }
}

//  - Others

.area_one_line{ font-size: 14px; height: 2.5em !important; }
.button_cell{ display: none; }
.loadMp3{ @extend .h6; }
.reduced_gaps_line{
  @extend .row;
  div{
    padding: auto 3px;
    input, select, textarea{ padding: auto 3px; }
  }
}
.paid{ @extend .text-success; }
.overdue{ @extend .text-danger; }
.h_notes{ @extend .handCursor; }
.apple_map{ background-position: -36px -6px; }
.google_map{ background-position: -7px -6px; }
.zmap{
  background-image: url('../images/map_icons');
  background-repeat: no-repeat;
  background-size: 70px;
  display: inline-block;
  height: 30px;
  width: 30px;
}
.the_modal_title{ text-transform: capitalize; }
.temp_alert{ @extend .d-block; @extend .alert; margin: 0px 20px !important; padding: 8px 15px; }

/* END of CUSTOM  */
